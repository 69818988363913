<template>
  <div class="wrapper">
    <div class="list">
      <div class="item ds_flex al_center">
        <div class="name">姓名</div>
        <div class="date">{{userInfo.user.nickName}}</div>
      </div>
      <div class="item ds_flex al_center">
        <div class="name">手机号码</div>
        <div class="date">{{userInfo.user.phoneNumber}}</div>
      </div>
      <div class="item ds_flex al_center">
        <div class="name">登录账号</div>
        <div class="date">{{userInfo.user.userName}}</div>
      </div>
    </div>
  </div>
</template>
<script>
let self = null;
export default {
  components: {
  },
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  created() {
    // console.log(JOSN.parse(localStorage.getItem('userInfo')))
    self = this;
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.ds_flex {
  display: flex;
  display: -webkit-flex;
}
.ju_center {
  justify-content: center;
  -webkit-justify-content: center;
}
.al_center {
  align-items: center;
  -webkit-align-items: center;
}
.wrapper {
  padding-top: 0.12rem;
  background: #f8f8f8;
  min-height: 100vh;
}
.list {
  padding: 0 0.35rem;
  background: #fff;
  .item {
    padding: 0.35rem 0;
    color: #333333;
    font-size: 0.28rem;
    border-bottom: 1px solid #f2f2f2;
    .name {
      width: 2.18rem;
      font-size: 0.3rem;
      color: #666666;
    }
    .date {
      flex: 1;
      -webkit-flex: 1;
      font-size: 0.3rem;
      color: #333333;
      // flex: 2;
      // -webkit-flex: 2;
    }
  }
  .item:last-child {
    border-bottom: none;
  }
}
</style>
